<template>
  <v-row>
    <v-col cols="2">
      <SelectBox 
        :selectStyleWidth="selectStyleWidth"
        :label="openTimeLbl"
        :value="openTime" 
        :items="timeItems" 
        @changeSelect="openTime = $event;timeUpdateSelect()" />
    </v-col>
    <v-col cols="2">
      <SelectBox 
        :selectStyleWidth="selectStyleWidth"
        :label="closeTimeLbl"
        :value="closeTime" 
        :items="timeItems" 
        @changeSelect="closeTime = $event;timeUpdateSelect()" />
    </v-col>
    <v-col cols="2">
      <SelectBox 
        :selectStyleWidth="selectStyleWidth"
        :label="lunchStartLbl"
        :value="lunchStart" 
        :items="timeItems" 
        @changeSelect="lunchStart = $event;timeUpdateSelect()" />
    </v-col>
    <v-col cols="2">
      <SelectBox 
        :selectStyleWidth="selectStyleWidth"
        :label="lunchCloseLbl"
        :value="lunchClose" 
        :items="timeItems" 
        @changeSelect="lunchClose = $event;timeUpdateSelect()" />
    </v-col>
    <v-col cols="2" v-if="isCloseShow">
      <v-checkbox
        label="휴일처리"
        v-model="isClose"
        @change="isClose = $event;timeUpdateSelect()"
      />
    </v-col>
  </v-row>
</template>
<script>
import SelectBox from "@/components/commonV2/ui/SelectBox.vue";
const formatService = require("@/utils/format.js");
export default {
  components: { SelectBox },
  props: {
    propsOpenTime: {
      type: [String],
    },
    propsCloseTime: {
      type: [String],
    },
    propsLunchStart: {
      type: [String],
    },
    propsLunchClose: {
      type: [String],
    },
    propsIsClose: {
      type: [Boolean],
    },
    openTimeLbl: {
      type: [String],
    },
    closeTimeLbl: {
      type: [String],
    },
    lunchStartLbl: {
      type: [String],
    },
    lunchCloseLbl: {
      type: [String],
    },
    isCloseShow: {
      type: [Boolean],
      default: true
    },
  },
  data() {
    return {
      selectStyleWidth: "width: 100%",
      timeItems: formatService.timeList(),
      openTime: this.propsOpenTime,
      closeTime: this.propsCloseTime,
      lunchStart: this.propsLunchStart,
      lunchClose: this.propsLunchClose,
      isClose: this.propsIsClose
    }
  },
  methods: {
    timeUpdateSelect() {
      const d = {
        openTime: this.openTime,
        closeTime: this.closeTime,
        lunchStart: this.lunchStart,
        lunchClose: this.lunchClose,
        isClose: this.isClose
      }
      this.$emit('timeUpdateSelect', d);
    }
  },
}
</script>
<style lang="scss">

</style>