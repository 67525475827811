<template>
  <div class="subMain">
    <div class="headTitle">병원 설명정보</div>
    <div class="hGap"></div>

    <!-- 병원정보 -->
    <TextArea 
      label="병원소개" 
      placeholder="병원소개"
      :isOutlined="false"
      :titleData="clinicData.hospitalText" 
      @complete="clinicData.hospitalText = $event"/>

    <div class="vGap" />

    <!-- 병원의 특활 분야 -->
    <TextArea 
      label="병원의 특활 분야" 
      placeholder="병원의 특활 분야"
      :isOutlined="false"
      :titleData="clinicData.hospitalSpecialText" 
      @complete="clinicData.hospitalSpecialText = $event"/>

    <div class="vGap" />

  </div>
</template>
<script>
import TextArea from "@/components/commonV2/ui/lang/TextArea.vue";
export default {
  components: { TextArea },
  props: {
    clinicData: {
      type: [Object, Array],
    },
  },
};
</script>