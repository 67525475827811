<template>
  <v-card-text>
    <v-form ref="form">
      <v-row justify="start" align="start">
        <v-col :cols="clinicData.hospital_id > 0 ? 6 : 12">
          <ClinicBasicInfo ref="refClinicBasicInfo" :clinicData="clinicData" />

          <div class="hGap" />

          <ClinicTimeInfo
            v-if="clinicData.salesTime !== null"
            ref="refClinicTimeInfo"
            :clinicData="clinicData"
          />

          <div class="hGap" />

          <ClinicAddInfo
            ref="refClinicAddInfo"
            v-if="clinicData.hospitalAddInfo !== null"
            :clinicData="clinicData"
          />
        </v-col>

        <v-col cols="6" v-if="clinicData.hospital_id > 0">
          <v-row justify="start" align="start">
            <ClinicChargeInfo
              ref="refClinicCertifyFile"
              :clinicData="clinicData"
            />

            <div class="hGap" />

            <ClinicCertifyFile
              ref="refClinicCertifyFile"
              :clinicData="clinicData"
            />

            <div class="hGap" />

            <ClinicDescription
              ref="refClinicDescription"
              :clinicData="clinicData"
            />
          </v-row>
        </v-col>
      </v-row>
    </v-form>
  </v-card-text>
</template>
<script>
import ClinicBasicInfo from "./clinic/ClinicBasicInfo.vue";
import ClinicAddInfo from "./clinic/ClinicAddInfo.vue";
import ClinicTimeInfo from "./clinic/ClinicTimeInfo.vue";
import ClinicCertifyFile from "./clinic/ClinicCertifyFile.vue";
import ClinicChargeInfo from "./clinic/ClinicChargeInfo.vue";
import ClinicDescription from "./clinic/ClinicDescription.vue";
// import Address from "@/components/commonV2/ui/AddressListItem.vue";
// const formatService = require("@/utils/format.js");
// const rulesService = require("@/utils/rules.js");

export default {
  components: {
    ClinicBasicInfo,
    ClinicAddInfo,
    ClinicTimeInfo,
    ClinicCertifyFile,
    ClinicChargeInfo,
    ClinicDescription
  },
  props: {
    clinicData: {
      type: [Object, Array],
    },
  },
  data() {
    return {
      // filedRules: rulesService.filedRules(),
      // emailRules: rulesService.emailRules(),
      // eligibleRules: rulesService.eligibleRules(),
      // cellRules: rulesService.cellRules(),
    };
  },
  methods: {},
};
</script>
<style lang="scss">
.headTitle {
  font-size: 20px;
  font-weight: 700;
  color: darkblue;
}
.hGap2 {
  height: 50px !important;
}
.hGap {
  height: 20px !important;
}
.subMain {
  width: 100%;
  ._vGap {
    height: 10px;
  }
  .edit_vGap {
    height: 7px;
  }
}
</style>