<template>
  <div class="subMain">
    <div class="headTitle">{{ $t("clinic.basicTitle") }}</div>
    <div class="_vGap"></div>
    <div class="edit_vGap" v-if="clinicData.user_id === 0"></div>
    <v-card-text>

      <!-- BI -->
      <S3FileUpload
        ref="hospitalImgS3FileUploader"
        uploadType="BI"
        accept="image/png, image/jpeg, image/bmp, image/gif"
        defLabel="병원 로고이미지"
        uploadDir="hospital"
        fileHeader="hospitalBi"
        defImage="profile_man"
        :fileDeleteOpt="fileDeleteOpt"
        :fileInfo="clinicData.hospitalBiFileInfo"
        @uploadComplete="imgUploadComplete"
        @changeDeleteOpt="imgChangeDeleteOpt"
      />

      <div class="hGap" />

      <!-- 병원종류 -->
      <SelectBoxHospitalType 
        selectStyleWidth="width: 100%"
        :isAllShow="false"
        :selectedValue="clinicData.hospitalType"
        @changeSelect="hospitalType_changeSelect"/>

      <TitleField 
        ref="refTitleField"
        :label="$t('clinic.detail.clinicName')" 
        :placeholder="$t('clinic.detail.clinicName')"
        :titleData="clinicData.hospitalName" 
        :rules="[filedRules.required, filedRules.counter]" 
        @complete="clinicData.hospitalName = $event"/>

      <v-row>
        <v-text-field
          label="병원소개 홈페이지 주소"
          placeholder="병원소개 홈페이지 주소"
          ref="refHomepageUrl"
          v-model="clinicData.homepageUrl"
        />
      </v-row>

      <!-- 주소 -->
      <Address :data="clinicData && clinicData.address" />

      <v-row>
        <v-col>
          <v-text-field
            :label="$t('clinic.detail.email')"
            :placeholder="$t('clinic.detail.email')"
            ref="refEmail"
            v-model="clinicData.email"
            :rules="[emailRules.required, emailRules.counter, emailRules.email]"
            required
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-text-field
            :label="$t('clinic.detail.mobile')"
            :placeholder="$t('clinic.detail.mobile')"
            ref="refCell"
            v-model="clinicData.cell"
            :rules="[cellRules.required, cellRules.min, cellRules.max]"
            required
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="6">
          <v-text-field
            ref="refAbleRoom"
            type="number"
            label="수술방 수량"
            placeholder="수술방 수량"
            v-model="clinicData.ableRoom"
          />
        </v-col>
        <v-col cols="6">
          <v-text-field
            ref="refEligible"
            v-model="clinicData.eligible"
            type="number"
            :label="$t('clinic.detail.eligible')"
            :placeholder="$t('clinic.detail.eligible')"
            :rules="[eligibleRules.required]"
          />
        </v-col>
      </v-row>
    </v-card-text>
  </div>
</template>
<script>
import S3FileUpload from "@/components/commonV2/ui/S3_ForFileUpload.vue";
import SelectBoxHospitalType from "@/components/commonV2/ui/SelectBoxHospitalType.vue";
import TitleField from "@/components/commonV2/ui/lang/TitleField.vue";
import TextArea from "@/components/commonV2/ui/lang/TextArea.vue";
import Address from "@/components/commonV2/ui/AddressListItemKr.vue";
const formatService = require("@/utils/format.js");
const rulesService = require("@/utils/rules.js");

export default {
  components: {
    S3FileUpload,
    SelectBoxHospitalType,
    Address,
    TitleField,
    TextArea
  },
  props: {
    clinicData: {
      type: [Object, Array],
    },
  },
  mounted() {
    this.fileDeleteOpt = false;

    setTimeout(() => {
      this.$refs.hospitalImgS3FileUploader._reset();
    }, 300);
  },
  data() {
    return {
      filedRules: rulesService.filedRules(),
      emailRules: rulesService.emailRules(),
      eligibleRules: rulesService.eligibleRules(),
      cellRules: rulesService.cellRules(),
      fileDeleteOpt: false,
    };
  },
  methods: {
    hospitalType_changeSelect(e) {
      this.clinicData.hospitalType = e;
      this.$emit("hospitalTypeChange", e);
    },

    imgChangeDeleteOpt(e) {
      this.fileDeleteRequestImg = e;
    },
    // S3업로드 처리 후
    imgUploadComplete(e) {
      console.log("imgUploadComplete", e);
      // console.log('첨부파일이 선택되지 않은경우임 -> 다음단계로 이동 처리');
      if (e !== null) {
        this.clinicData.hospitalBiImg = e.Location;
        this.clinicData.hospitalBiFileInfo = {
          filename: this.$helper.findFileName(e.key),
          fullURL: e.Location,
          isImageFile: this.$helper.checkImageFile(
            this.$helper.findFileName(e.key)
          ),
        };
      }
      console.log("this.clinicData.hospitalBiFileInfo", this.clinicData.hospitalBiFileInfo);
      this.$forceUpdate();
    },
  },
};
</script>
<style lang="scss">
</style>