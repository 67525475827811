<template>
  <div class="subMain">
    <v-card-text>
      <v-row>
        <v-col>
          <DatePickerWithInputText
            label="계약일자"
            selectStyleWidth="width: 100%"
            :value="clinicData.hospitalAddInfo.contractDate"
            @onChangeDate="onChangeContractDateDatePicker"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <DatePickerWithInputText
            label="병원설립일"
            selectStyleWidth="width: 100%"
            :value="clinicData.hospitalAddInfo.establishmentDate"
            @onChangeDate="onChangeEstablishmentDatePicker"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-combobox
            label="통역사 보유(선택)"
            v-model="clinicData.hospitalAddInfo.isHaveInterpreterArr"
            :items="itemsIsHaveInterpreter"
            chips
            multiple
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-combobox
            label="결재방법 제공(선택)"
            v-model="clinicData.hospitalAddInfo.payMethodArr"
            :items="itemsPayMethod"
            chips
            multiple
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-combobox
            label="서비스 출시국가"
            v-model="clinicData.hospitalAddInfo.releaseNationArr"
            :items="itemsReleaseNation"
            chips
            multiple
          />
        </v-col>
      </v-row>
    </v-card-text>
  </div>
</template>
<script>
import SelectBox from "@/components/commonV2/ui/SelectBox.vue";
import DatePickerWithInputText from "@/components/commonV2/ui/DatePickerWithInputText.vue";
const formatService = require("@/utils/format.js");
const rulesService = require("@/utils/rules.js");

export default {
  components: {
    SelectBox,
    DatePickerWithInputText,
  },
  props: {
    clinicData: {
      type: [Object, Array],
    },
  },
  data() {
    return {
      selectedInterpreter: [],
      itemsIsHaveInterpreter: formatService.itemsIsHaveInterpreter(),
      itemsPayMethod: formatService.itemsPayMethod(),
      itemsReleaseNation: formatService.itemsReleaseNation(),
    };
  },
  methods: {
    onChangeContractDateDatePicker(e) {
      console.log("e", e);
      this.clinicData.hospitalAddInfo.contractDate = e;
    },
    onChangeEstablishmentDatePicker(e) {
      this.clinicData.hospitalAddInfo.establishmentDate = e;
    },
  },
};
</script>
<style lang="scss">
</style>