<template>
  <v-card-text>

    <v-btn v-if="clinicData.hospital_id > 0" class="btn" color="primary mt-5" depressed small @click="clickEditSaleTimes">
      진료시간정보 관리
    </v-btn>

    <DialogClinicSaleTimes ref="refDialogClinicSaleTimes" />

    <!-- <v-row>
      <v-col cols="6">
        <v-text-field
          label="월~금요일(진료시작)"
          ref="refOpenTime"
          placeholder="09:00"
          v-model="clinicData.salesTime.openTime"
        />
      </v-col>
      <v-col cols="6">
        <v-text-field
          label="월~금요일(진료종료)"
          ref="refCloseTime"
          placeholder="17:00"
          v-model="clinicData.salesTime.closeTime"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="6">
        <v-text-field
          label="토요일(진료시작)"
          ref="refOpenTime2"
          placeholder="10:00"
          v-model="clinicData.salesTime.openTime2"
        />
      </v-col>
      <v-col cols="6">
        <v-text-field
          label="토요일(진료종료)"
          ref="refCloseTime2"
          placeholder="16:00"
          v-model="clinicData.salesTime.closeTime2"
        />
      </v-col>
    </v-row> -->
  </v-card-text>
</template>
<script>
import DialogClinicSaleTimes from "@/components/commonV2/Dialog/DialogClinicSaleTimes.vue";
export default {
  components: { DialogClinicSaleTimes },
  props: {
    clinicData: {
      type: [Object, Array],
    },
  },
  data() {
    return {};
  },
  methods: {
    clickEditSaleTimes() {
      this.$refs.refDialogClinicSaleTimes.showModal(this.clinicData);
    }
  },
};
</script>
<style lang="">
</style>