<template>
  <v-dialog v-model="visible" max-width="1000px" scrollable>
    <v-card class="editorPadding">
      <Header @close="close" />

      <v-card-title> 병원진료시간 관리 </v-card-title>
      <v-card-text>

        <div class="local_vGap" />
          
        <div v-if="!saleTimesModel.isDetail">
          <SaleTimes 
            :propsOpenTime="saleTimesModel.openTime" 
            :propsCloseTime="saleTimesModel.closeTime" 
            :propsLunchStart="saleTimesModel.lunchStart" 
            :propsLunchClose="saleTimesModel.lunchClose" 
            :isCloseShow="false"
            openTimeLbl="월~금요일(진료시작)"
            closeTimeLbl="월~금요일(진료종료)"
            lunchStartLbl="월~금요일(점심시작시간)"
            lunchCloseLbl="월~금요일(점심종료시간)"
            @timeUpdateSelect="timeUpdateSelect('commonDay', $event)"/>
          <SaleTimes 
            :propsOpenTime="saleTimesModel.openTime2" 
            :propsCloseTime="saleTimesModel.closeTime2" 
            :propsLunchStart="saleTimesModel.lunchStart2" 
            :propsLunchClose="saleTimesModel.lunchClose2" 
            :isCloseShow="true"
            :propsIsClose="saleTimesModel.commonSat_isClose" 
            openTimeLbl="토요일(진료시작)"
            closeTimeLbl="토요일(진료종료)"
            lunchStartLbl="토요일(점심시작시간)"
            lunchCloseLbl="토요일(점심종료시간)"
            @timeUpdateSelect="timeUpdateSelect('commonSat', $event)"/>

          <SaleTimes 
            :propsOpenTime="saleTimesModel.openTime3" 
            :propsCloseTime="saleTimesModel.closeTime3" 
            :propsLunchStart="saleTimesModel.lunchStart3" 
            :propsLunchClose="saleTimesModel.lunchClose3" 
            :isCloseShow="true"
            :propsIsClose="saleTimesModel.commonSun_isClose" 
            openTimeLbl="일요일/공휴일(진료시작)"
            closeTimeLbl="일요일/공휴일(진료종료)"
            lunchStartLbl="일요일/공휴일(점심시작시간)"
            lunchCloseLbl="일요일/공휴일(점심종료시간)"
            @timeUpdateSelect="timeUpdateSelect('commonSun', $event)"/>

        </div>

        <div>
          <div>
            <v-checkbox
              label="상세관리"
              v-model="saleTimesModel.isDetail"
              @change="saleTimesModel.isDetail = $event"
            />
          </div>
          <div v-if="saleTimesModel.isDetail">
            <SaleTimes 
              :propsOpenTime="saleTimesModel.mon_openTime" 
              :propsCloseTime="saleTimesModel.mon_closeTime" 
              :propsLunchStart="saleTimesModel.mon_lunchStart" 
              :propsLunchClose="saleTimesModel.mon_lunchClose" 
              :isCloseShow="true"
              :propsIsClose="saleTimesModel.mon_isClose" 
              openTimeLbl="월(진료시작)"
              closeTimeLbl="월(진료종료)"
              lunchStartLbl="월(점심시작시간)"
              lunchCloseLbl="월(점심종료시간)"
              @timeUpdateSelect="timeUpdateSelect('mon', $event)"/>

            <SaleTimes 
              :propsOpenTime="saleTimesModel.tue_openTime" 
              :propsCloseTime="saleTimesModel.tue_closeTime" 
              :propsLunchStart="saleTimesModel.tue_lunchStart" 
              :propsLunchClose="saleTimesModel.tue_lunchClose" 
              :isCloseShow="true"
              :propsIsClose="saleTimesModel.tue_isClose" 
              openTimeLbl="화(진료시작)"
              closeTimeLbl="화(진료종료)"
              lunchStartLbl="화(점심시작시간)"
              lunchCloseLbl="화(점심종료시간)"
              @timeUpdateSelect="timeUpdateSelect('tue', $event)"/>

            <SaleTimes 
              :propsOpenTime="saleTimesModel.wed_openTime" 
              :propsCloseTime="saleTimesModel.wed_closeTime" 
              :propsLunchStart="saleTimesModel.wed_lunchStart" 
              :propsLunchClose="saleTimesModel.wed_lunchClose" 
              :isCloseShow="true"
              :propsIsClose="saleTimesModel.wed_isClose" 
              openTimeLbl="수(진료시작)"
              closeTimeLbl="수(진료종료)"
              lunchStartLbl="수(점심시작시간)"
              lunchCloseLbl="수(점심종료시간)"
              @timeUpdateSelect="timeUpdateSelect('wed', $event)"/>
            
            <SaleTimes 
              :propsOpenTime="saleTimesModel.thu_openTime" 
              :propsCloseTime="saleTimesModel.thu_closeTime" 
              :propsLunchStart="saleTimesModel.thu_lunchStart" 
              :propsLunchClose="saleTimesModel.thu_lunchClose" 
              :isCloseShow="true"
              :propsIsClose="saleTimesModel.thu_isClose" 
              openTimeLbl="목(진료시작)"
              closeTimeLbl="목(진료종료)"
              lunchStartLbl="목(점심시작시간)"
              lunchCloseLbl="목(점심종료시간)"
              @timeUpdateSelect="timeUpdateSelect('thu', $event)"/>

            <SaleTimes 
              :propsOpenTime="saleTimesModel.fri_openTime" 
              :propsCloseTime="saleTimesModel.fri_closeTime" 
              :propsLunchStart="saleTimesModel.fri_lunchStart" 
              :propsLunchClose="saleTimesModel.fri_lunchClose" 
              :isCloseShow="true"
              :propsIsClose="saleTimesModel.fri_isClose" 
              openTimeLbl="금(진료시작)"
              closeTimeLbl="금(진료종료)"
              lunchStartLbl="금(점심시작시간)"
              lunchCloseLbl="금(점심종료시간)"
              @timeUpdateSelect="timeUpdateSelect('fri', $event)"/>

            <SaleTimes 
              :propsOpenTime="saleTimesModel.sat_openTime" 
              :propsCloseTime="saleTimesModel.sat_closeTime" 
              :propsLunchStart="saleTimesModel.sat_lunchStart" 
              :propsLunchClose="saleTimesModel.sat_lunchClose" 
              :isCloseShow="true"
              :propsIsClose="saleTimesModel.sat_isClose" 
              openTimeLbl="토(진료시작)"
              closeTimeLbl="토(진료종료)"
              lunchStartLbl="토(점심시작시간)"
              lunchCloseLbl="토(점심종료시간)"
              @timeUpdateSelect="timeUpdateSelect('sat', $event)"/>

            <SaleTimes 
              :propsOpenTime="saleTimesModel.sun_openTime" 
              :propsCloseTime="saleTimesModel.sun_closeTime" 
              :propsLunchStart="saleTimesModel.sun_lunchStart" 
              :propsLunchClose="saleTimesModel.sun_lunchClose" 
              :isCloseShow="true"
              :propsIsClose="saleTimesModel.sun_isClose" 
              openTimeLbl="일(진료시작)"
              closeTimeLbl="일(진료종료)"
              lunchStartLbl="일(점심시작시간)"
              lunchCloseLbl="일(점심종료시간)"
              @timeUpdateSelect="timeUpdateSelect('sun', $event)"/>

            <SaleTimes 
              :propsOpenTime="saleTimesModel.hal_openTime" 
              :propsCloseTime="saleTimesModel.hal_closeTime" 
              :propsLunchStart="saleTimesModel.hal_lunchStart" 
              :propsLunchClose="saleTimesModel.hal_lunchClose" 
              :isCloseShow="true"
              :propsIsClose="saleTimesModel.hal_isClose" 
              openTimeLbl="공휴일(진료시작)"
              closeTimeLbl="공휴일(진료종료)"
              lunchStartLbl="공휴일(점심시작시간)"
              lunchCloseLbl="공휴일(점심종료시간)"
              @timeUpdateSelect="timeUpdateSelect('hal', $event)"/>

          </div>
        </div>
      </v-card-text>

      <CommonBtn
        :propsKey="saleTimesModel.saleTime_id"
        :isShowDeleteBtn="false"
        @save="clickSave"
        @delete="clickDelete"
        @cancel="close"
      />
    </v-card>
  </v-dialog>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import Header from "./comp/Header.vue";
import CommonBtn from "./medicalInfo/comp/CommonBtn.vue";
import SaleTimes from "@/components/commonV2/Dialog/form/clinic/comp/SaleTimes.vue";

const rulesService = require("@/utils/rules.js");
const modelService = require("@/utils/dataModel.js");
const formatService = require("@/utils/format.js");
const requireService = require("@/utils/requiredValue.js");

export default {
  components: {
    Header,
    CommonBtn,
    SaleTimes
  },
  data() {
    return {
      visible: false,
      isDetail: false,
      saleTimesModel: modelService.hospitalSaleTimesModel(),
      targetModel: modelService.hospitalSaleTimesModel(),
    };
  },
  methods: {
    // changeIsDetail(e) {
    //   console.log('e', typeof e);
    //   this.saleTimesModel.isDetail = e;
    // },
    showModal(clinicData) {
      this.visible = true;
      this.clinicData = clinicData;
      this.targetModel.hospital_id = clinicData.hospital_id
      this.getData();
    },
    close() {
      this.visible = false;
    },
    getData() {
      this.getDataQuery(this.targetModel).then((res) => {
        // console.log('res', res);
        if(res === null || res === undefined || res === "") {
          this.saleTimesModel = modelService.hospitalSaleTimesModel();
          // console.log('this.saleTimesModel', this.saleTimesModel);
          return;
        }
        this.saleTimesModel = res;
      })
    },
    clickSave() {
      if (!confirm("병원진료시간 정보를 수정하시겠습니까?")) return;
      this.saveData();
    },
    clickDelete() {
      if (!confirm("delete?")) return;
      this.saleTimesModel.useYN = "N";
      this.saveData();
    },
    saveData() {
      const $h = this.$helper;
      this.saleTimesModel.endpoint = "hospitalSaleTimes";
      this.saleTimesModel.hospital_id = this.clinicData.hospital_id;
      this.saveDataQuery(this.saleTimesModel).then((res) => {
        if(res === undefined) return;
        // console.log('res', res);
        $h.showTostMessage("", $h.commonMessage("S"), "success");
        this.close();
      })
    },
    timeUpdateSelect(type, e) {
      console.log('timeUpdateSelect', type, e);
      if(type === 'commonDay') {
        this.saleTimesModel.openTime = e.openTime;
        this.saleTimesModel.closeTime = e.closeTime;
        this.saleTimesModel.lunchStart = e.lunchStart;
        this.saleTimesModel.lunchClose = e.lunchClose;
      } else if(type === 'commonSat') {
        this.saleTimesModel.openTime2 = e.openTime;
        this.saleTimesModel.closeTime2 = e.closeTime;
        this.saleTimesModel.lunchStart2 = e.lunchStart;
        this.saleTimesModel.lunchClose2 = e.lunchClose;
        this.saleTimesModel['commonSat_isClose'] = e.isClose;
      } else if(type === 'commonSun') {
        this.saleTimesModel.openTime3 = e.openTime;
        this.saleTimesModel.closeTime3 = e.closeTime;
        this.saleTimesModel.lunchStart3 = e.lunchStart;
        this.saleTimesModel.lunchClose3 = e.lunchClose;
        this.saleTimesModel['commonSun_isClose'] = e.isClose;
      } else {
        this.saleTimesModel[type+'_openTime'] = e.openTime;
        this.saleTimesModel[type+'_closeTime'] = e.closeTime;
        this.saleTimesModel[type+'_lunchStart'] = e.lunchStart;
        this.saleTimesModel[type+'_lunchClose'] = e.lunchClose;
        this.saleTimesModel[type+'_isClose'] = e.isClose;
      }
      console.log('saleTimesModel', this.saleTimesModel);
      
    },
    ...mapActions(["getDataQuery", "saveDataQuery"]),
  },
};
</script>
<style lang="scss">
.local_vGap {
  height: 10px;
}
</style>